<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <!--Container 3 -->

    <div style="clear: both; display: block; height: 0px;"></div>

    <!--Container - Popular Resorts -->
    <div class="w-full mx-auto pt-0 bg-white">
      <div
        class="flex w-full  bg-gradient-to-r from-gray-100 via-blue-100 to-green-100 text-left"
      >
        <h1
          class="text-2xl font-bold my-8 max-w-7xl md:mx-auto md:pl-4 mx-auto px-2 sm:px-6"
        >
Covid-19 Updates
         </h1>
      </div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="flex flex-wrap ">
          <div class="mx-2 text-left">
              <p class="my-8 mb-40">
                  
                  For more information please click below the Ministry of Health link. <br>
                  
                   <a href="https://covid19.health.gov.mv/en/?c=0">
https://covid19.health.gov.mv/en/?c=0 </a>

                   </p>

         
             
 
          </div>
        </div>
      </div>
    </div>

    <FooterLayout> </FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";

export default {
  name: "CovidPage",
  data() {
    return {
      properties: [],
      totalPages: "",
      currentPage: this.$route.query.page * 1 || 1
    };
  },
  mounted() {
    this.getProperties();
  },
  methods: {
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties`
        );
        this.properties = response.data.properties;
        this.totalPages = response.data.totalPages;
        console.log("hello");
      } catch (e) {
        console.log(e);
      }
    }
  },
  nextPage() {
    if (this.canGoNextPage) {
      this.currentPage += 1;
      this.getProperties();
    }
  },
  previousPage() {
    if (this.canGoPreviousPage) {
      this.currentPage -= 1;
      this.getProperties();
    }
  },
  computed: {
    canGoNextPage() {
      return this.currentPage + 1 <= this.totalPages;
    },
    canGoPreviousPage() {
      return this.currentPage - 1 > 0;
    }
  },
  components: {
    HeaderLayout,
    FooterLayout
  }
};
</script>

<style scoped></style>
